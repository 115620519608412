<template>
    <el-container>
        <el-aside width="200px">
            <div class="nav">
                <el-menu :default-active="activeIndex"
                         router mode="vertical"
                         active-text-color="#f29100"
                         text-color="#3a3a3a"
                         class="title">
                    <el-menu-item index="/abroad/abroad">出国留学</el-menu-item>
                    <el-menu-item index="/abroad/camp">保研夏令营</el-menu-item>
                </el-menu>
            </div>
        </el-aside>
        <el-main style="padding: 0 20px;">
            <router-view/>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            activeIndex: this.$route.path
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-menu {
    border-right: none !important;
}

::v-deep .el-menu-item {
    background-color: transparent !important;
    border-left: 4px solid #FFFFFF;
}

::v-deep .el-menu-item.is-active {
    border-left: 4px solid $sr-main-color;
    background: rgb(249, 249, 249) !important;
}

.nav {
    background-color: #FFFFFF;
    padding: 20px 0;
    min-height: 300px;
    border-radius: 4px;

    .title {
        li {
            font-size: 18px;
            text-align: center;

            &li:hover {
                background-color: transparent !important;
            }
        }
    }
}


</style>